@import 'reset.css';
@import 'fonts.css';
@import 'libs.less';
@import 'antd/es/style/themes/default.less';

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-display: swap;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.57;
}

:root {
  --ant-error-color: @red-5;
  --ant-primary-color: @blue-base;
  --ant-primary-color-hover: #40a9ff;
  --ant-primary-link-color: #1890ff;
  --ant-primary-color-active: #096dd9;
}
