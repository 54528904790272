@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/public/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('/public/fonts/Montserrat-Bold.woff') format('woff');
}
/* roboto-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-500.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-900.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local(''),
    url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('/public/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-900italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
